import React, { FC } from 'react';
import { graphql, Link, PageProps } from 'gatsby';
import { Layout } from '../components/Layout';
import { CategoryData, AuthorData, TextTaleData } from '../types';
import { makeStyles } from '@material-ui/core/styles';
import { getFieldsForTalePreview } from '../helpers/getFieldsForTalePreview';

interface DataProps {
    site: {
        siteMetadata: {
            title: string
        }
    }
    allTextTales: {
        nodes: Pick<TextTaleData, 'slug' | 'name'>[];
    }
    allImagesTales: {
        nodes: Pick<TextTaleData, 'slug' | 'name'>[];
    }
    allAuthors: {
        nodes: Pick<AuthorData, 'slug' | 'name'>[];
    }
    allCategories: {
        nodes: Pick<CategoryData, 'slug' | 'name'>[];
    }
}

const useStyles = makeStyles((theme) => ({
    column: {
        padding: theme.spacing(2),
        boxSizing: 'border-box',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '33%'
        }
    },
    wrap: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    }
}));

const Sitemap: FC<PageProps<DataProps>> = ({ location, data }) => {
    const { allTextTales, allAuthors, allCategories, allImagesTales } = data;
    const classes = useStyles();

    const textTales = allTextTales.nodes.map((props) => ({...props, type: 'text'}));
    const imagesTales = allImagesTales.nodes.map((props) => ({...props, type: 'images'}));

    return (
        <Layout seoTitle='Карта сайта' location={location} withoutSidebar>
            <div className={classes.wrap}>
                <div className={classes.column}>
                    <h2>Категории</h2>
                    <ul>
                        {allCategories.nodes.map(({ slug, name }) => (
                            <li key={`categories-${slug}`}><Link to={`/categories/${slug}`}>{name}</Link></li>
                        ))}
                    </ul>
                </div>
                <div className={classes.column}>
                    <h2>Авторы</h2>
                    <ul>
                        {allAuthors.nodes.map(({ slug, name }) => (
                            <li key={`authors-${slug}`}><Link to={`/authors/${slug}`}>{name}</Link></li>
                        ))}
                    </ul>
                </div>
                <div className={classes.column}>
                    <h2>Сказки</h2>
                    <ul>
                        {[...textTales, ...imagesTales].map(({ slug, name, type }) => (
                            <>
                                {type === 'text' && (
                                    <li key={`text-tales-${slug}`}><Link to={`/text-tales/${slug}`}>{name}</Link></li>
                                )}
                                {type === 'images' && (
                                    <li key={`images-tales-${slug}`}><Link to={`/images-tales/${slug}`}>{name}</Link></li>
                                )}
                            </>
                        ))}
                    </ul>
                </div>
            </div>

        </Layout>
    );
}

export default Sitemap;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allAuthors {
            nodes {
                slug
                name
            }
        }
        allTextTales {
            nodes {
                slug
                name
            }
        }
        allImagesTales {
            nodes {
                slug
                name
            }
        }
        allCategories {
            nodes {
                slug
                name
            }
        }
    }
`;
